const resizingSelects = document.querySelectorAll(".select");
const helperElement = document.querySelector(".help-resize-element");

function changeHandler(event) {
  resize(event.target);
}

function resize(target) {
  helperElement.innerHTML = target.querySelector(
    "option:checked"
  ).innerText;
  target.style.setProperty("width", `${helperElement.offsetWidth + 30}px`);
}

resizingSelects.forEach(select => {
  resize(select);
  select.addEventListener("change", changeHandler);
});