// 
// user.js
// Use this to write your custom JS
//


const useCaseSelect = document.querySelectorAll('.use-case-select');

useCaseSelect.forEach((select) => {
    const menuItem = select.querySelectorAll('.dropdown-item')
    const currentItem = select.querySelector('.current-item')
    menuItem.forEach((item) => {
        item.addEventListener('click', function (event) {
            currentItem.innerHTML = item.innerHTML;
            const useCaseCards = document.querySelectorAll('.preview-card-article');
            useCaseCards.forEach(element => {
                const tags = element.querySelectorAll('.tag');
                const searchValues = event.target.getAttribute('value').split(',')
                if (!searchValues || searchValues.some((value) => {
                    for (let index = 0; index < tags.length; index++) {
                        if (tags[index].textContent.toLocaleLowerCase().includes(value.trim().toLocaleLowerCase())) {
                            return true;
                        }
                    }
                })) {
                    element.style.display = 'initial';
                }
                else {
                    element.style.display = 'none';
                }
            });
        });

    })
});

const printPageButtons = document.querySelectorAll('.print-page-button');
printPageButtons.forEach((button) => {
    button.addEventListener('click', function (event) {
        window.print()
    });
});

function sendContact(event, topic) {
    event.target.disabled = true;
    event.preventDefault(); // Prevent the form from submitting

    grecaptcha.ready(function () {
        grecaptcha.execute('6Lf3QNAlAAAAAEhCG-ZjJfnavmLJcFNRUvNZmjJU', { action: 'submit' }).then(function (token) {
            // Send the token to your server for verification
            formElement = document.querySelector('#contact-form');
            formElement.classList.remove("was-validated");
            setTimeout(() => {
                if (!formElement.checkValidity()) {
                    formElement.classList.add("was-validated");
                    event.preventDefault();
                    event.stopPropagation();
                    // Display error message
                    document.getElementById('notification').classList.add('text-danger');
                    document.getElementById('notification').classList.remove('text-success');
                    document.getElementById('notification').textContent = 'Please fill out the required fields!';
                    event.target.disabled = false;
                    return;
                }
                formData = new FormData(formElement);
                formData.set('captcha', token);

                // Send the form data to the backend using AJAX or fetch
                fetch(`https://api-prod-5z3nodwvta-oa.a.run.app/api/v0/contact-us/${topic}`, {
                    method: 'POST',
                    body: formData
                })
                    .then(response => response.json())
                    .then(data => {
                        // Display success message
                        formElement.reset();
                        document.getElementById('notification').classList.remove('text-danger');
                        document.getElementById('notification').classList.add('text-success');
                        document.getElementById('notification').textContent = 'Thank you for your message. Our team will get back to you shortly!';
                    })
                    .catch((error) => {
                        // Display error message
                        document.getElementById('notification').classList.add('text-danger');
                        document.getElementById('notification').classList.remove('text-success');
                        document.getElementById('notification').textContent = 'Something went wrong!';
                        currentItem.innerHTML = item.innerHTML;
                        const useCaseCards = document.querySelectorAll('.preview-card-article');
                        useCaseCards.forEach(element => {
                            const tags = element.querySelectorAll('.tag');
                            const searchValues = event.target.getAttribute('value').split(',')
                            if (!searchValues || searchValues.some((value) => {
                                for (let index = 0; index < tags.length; index++) {
                                    if (tags[index].textContent.toLocaleLowerCase().includes(value.trim().toLocaleLowerCase())) {
                                        return true;
                                    }
                                }
                            })) {
                                element.style.display = 'initial';
                            }
                            else {
                                element.style.display = 'none';
                            }
                        });
            
                    }).finally(() => {
                        event.target.disabled = false;
                    });
            }, 500);
        });
    });
}

function goBack() {
    if (document.referrer.startsWith(window.location.origin)) {
        window.history.back()
    } else {
        // User came from an external website
        // Going back would take them away from our website
    }
}

function playVideo(video) {
    const videoFrame = document.getElementById('video-iframe');
    videoFrame.setAttribute('src', `${video}?&autoplay=1&mute=1`);
}

window.onload = function (e) {
    const popup = document.querySelector('.cookie-popup');
    if (!localStorage.getItem('cookies')) {
        popup.style.display = 'initial';
    } else {
        popup.style.display = 'none';
    }
}

function userCookieResponse() {
    localStorage.setItem('cookies', 'accepted');
    const popup = document.querySelector('.cookie-popup');
    popup.style.display = 'none';
}

function platformActions(event) {
    var svgDoc = event.target.contentDocument;
    var rects = svgDoc.querySelectorAll('.product-link');
  
    
    rects.forEach( (rect) => 
      rect.addEventListener('mouseover', function(event) {
          event.target.style.boxShadow = 'rgba(100, 255, 255, 0.8) 0px 0px 5px 3px';
      }
    ));
  
    rects.forEach( (rect) => 
      rect.addEventListener('mouseout', function(event) {
          event.target.style.boxShadow = '';
      }
    ));
}

function searchUseCases(event) {
    const useCaseCards = document.querySelectorAll('.preview-card-article');
    useCaseCards.forEach(element => {
        const tags = element.querySelectorAll('.tag');
        const searchValues = event.target.value;
        for (let index = 0; index < tags.length; index++) {
            if (tags[index].textContent.toLocaleLowerCase().includes(searchValues.toLocaleLowerCase())) {
                element.style.display = 'initial';
                return;
            }
        }
        element.style.display = 'none';
    });
}

// Make available globally
window.sendContact = sendContact;
window.userCookieResponse = userCookieResponse;
window.goBack = goBack;
window.playVideo = playVideo;
window.searchUseCases = searchUseCases;
window.platformActions = platformActions;


document.addEventListener("DOMContentLoaded", function() {
    var lazyImages = [].slice.call(document.querySelectorAll("img.lazy-load"));

    if ("IntersectionObserver" in window) {
        let lazyImageObserver = new IntersectionObserver(function(entries, observer) {
            entries.forEach(function(entry) {
                if (entry.isIntersecting) {
                    let lazyImage = entry.target;
                    lazyImage.src = lazyImage.dataset.src;
                    lazyImage.classList.remove("lazy-load");
                    lazyImageObserver.unobserve(lazyImage);
                }
            });
        });

        lazyImages.forEach(function(lazyImage) {
            lazyImageObserver.observe(lazyImage);
        });
    } else {
        // Fallback for browsers that don't support IntersectionObserver
        lazyImages.forEach(function(lazyImage) {
            lazyImage.src = lazyImage.dataset.src;
            lazyImage.classList.remove("lazy-load");
        });
    }
});
