let navLinks = document.querySelectorAll('.page-nav-item');
let navSections = document.querySelectorAll('.page-nav-section');

function throttle(fn, wait) {
  let lastTime = 0;
  return function(...args) {
    const now = new Date().getTime();
    if (now - lastTime >= wait) {
      lastTime = now;
      return fn(...args);
    }
  };
}

window.onscroll = throttle(function () {
  let current = "media-1";

  navSections.forEach(section => {
    const sectionTop = section.offsetTop;
    const sectionHeight = section.offsetHeight;
    const scrollPosition = window.scrollY || document.documentElement.scrollTop;

    // Adjust the threshold dynamically based on window height
    if (scrollPosition >= sectionTop && scrollPosition < sectionTop + sectionHeight + 200) {
      const anchor = section.querySelector('.anchor');
      if (anchor) {
        current = anchor.id;
      }
    }
  });

  navLinks.forEach(link => {
    link.classList.remove('active');
    if (link.href.split('#')[1] === current) {
      link.classList.add("active");
    }
  });
}, 100); // Throttle to run every 100ms
