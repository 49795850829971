import $ from "jquery";
import "slick-carousel";

$(document).ready(function(){
  $('.vertical-carousel').slick({
    dots: true,
    infinite: true,
    speed: 700,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    rtl: false
  });
});

$(document).ready(function(){
  $('.horizontal-carousel').slick({
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  });
});